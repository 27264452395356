export default {
  button: {
    edit: 'Edit',
    modify: 'Modify',
    return: 'Return',
    add: 'Add',
    del: 'Delete',
    authorization: 'Authorization',
    delMessage: 'Are you sure to delete it?',
    modifyMessage: 'Are you sure about the modification?',
    cancel: 'no',
    confirm: 'yes',
    save: 'save',
    copy: 'copy'
  },
  route: {
    
  },
  navbar: {
    title: 'Cashier',
  },
  qr: {
    selectBank: 'You have not yet bound your bank card. Please choose your bank card for binding.',
    amount: 'Amount：',
    ordernumber: 'Order No：',
    tips: 'Warning: each QR code can only be paid once, the second time cannot be received.',
    loadingQR: 'Loading the QR code...'
  },
  transfer: {
    header: 'Cashier',
    send: 'send',
    padding: 'Payment is in progress, please hold on...'
  },
  result: {
    error: 'Your payment order has expired, please re-create the order. If you have any questions, please feel free to contact us.',
    success: 'Successful payment!'
  },
  online: {
    bankName: 'Bank Name',
    payInfo: 'Payment Information',
    return: 'Return',
    loadingText: 'In processing... Please be patient',
    ordernumber: 'Order no',
    createdTime: 'Created Time',
    username: 'User ID',
    password: 'Password',
    loginText: 'Login',
    safetyTips_1: '*Secure Internet Banking: Please use yours',
    safetyTips_2: 'login with your account and password.',
    timeTips_1: 'You will log out in ',
    timeTips_2: 'second!',
    h5_safetyTips: '*Secure online banking',
    payInfotitle: 'Transaction Info',
    bank: 'Bank：',
    amount: 'Amount：',
    accountNo: 'User ID：',
    time: 'time',
    otp_title: 'Add 3rd party account - confirm.',
    otp_tips: 'You will need to add a third party account to our bank, in the list of accounts to perform this transaction.',
    otp_scb_tips: 'The time of OTP',
    otp_tcb_tips: 'The OTP Transaction ID',
    otp_other_tips: 'The OTP REF',
    otp_other_tips1: 'Transaction ID',
    otp_pin_tips: 'The PIN CODE REF',
    otp_input_tips: 'Please enter your OTP：',
    confirm: 'Confirm',
    bottom: {
      title: 'Note：',
      tips1: 'Vui lòng sử dụng tài khoản online banking để đăng nhập, hãy chắc rằng tài khoản đã kích hoạt chức năng chuyển khoản online',
      tips2: 'Thực hiện các bước tuần tự theo hướng dẫn, quá trình có thể mất vài phút tuỳ thuộc tốc độ kết nối',
      tips3: 'Lưu giữ thông tin giao dịch, liên lạc với chúng tôi khi gặp vấn đề'
    },
    steps: {
      step1: 'Choose a bank',
      step2: 'Confirm payment information',
      step3: 'Online banking login'
    },
    stepsWeb: {
      step1: 'Online banking login',
      step2: 'Fill in the OTP',
      step3: 'OK'
    },
    stepsH5: {
      step1: 'Login',
      step2: 'authorization',
      step3: 'OK'
    }
  }
}
