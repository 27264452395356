import { RouteConfig } from 'vue-router'
import Layout from '@/layout/index.vue'

const indiah5Routes: RouteConfig = {
  path: '/new_indiah5',
  component: Layout,
  redirect: '/new_indiah5/selBank',
  meta: {
    title: 'indiah5',
    icon: 'table',
  },
  children: [
    {
      path: 'selBank',
      name: 'selBank',
      component: () => import('@/views/indiah5/selBank.vue'),
      meta: {
        // title: 'depositList',
        title: 'selBank',
        icon: 'excel',
      }
    },
    {
      path: 'submit',
      name: 'submit',
      component: () => import('@/views/indiah5/submit.vue'),
      meta: {
        // title: 'depositList',
        title: 'submit',
        icon: 'excel',
      }
    },
  ]

}

export default indiah5Routes