/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'timer': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M843.19 387.78a26 26 0 0048.69-18.26 401.37 401.37 0 00-11.44-27.38 26 26 0 00-47.21 21.8c3.6 7.8 6.95 15.82 9.96 23.84zM636.16 180.79c8 3 16.06 6.36 23.86 10a26 26 0 0021.79-47.21c-8.95-4.13-18.17-8-27.39-11.44a26 26 0 10-18.26 48.69zM752.71 253c6.3 5.85 12.45 12 18.3 18.29a26 26 0 1038.08-35.41c-6.7-7.21-13.76-14.26-21-21A26 26 0 00752.71 253zm164.56 242.12a26 26 0 10-51.95 2.13c.2 4.88.3 9.84.3 14.75A353.62 353.62 0 11512 158.38c4.9 0 9.86.1 14.75.3h1.09a26 26 0 001-52c-5.6-.23-11.28-.35-16.88-.35a405.71 405.71 0 00-157.85 779.4A405.51 405.51 0 00917.62 512c0-5.61-.12-11.29-.35-16.88z"/><path pid="1" d="M524.65 486L379.52 301a26 26 0 10-40.92 32.1L499.35 538h260.46a26 26 0 000-52z"/>'
  }
})
