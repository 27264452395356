import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule
} from 'vuex-module-decorators'
import { getToken, setToken, removeToken } from '@/utils/cookies'
import store from '@/store'

export interface IUserState {
  token: string
  baseUrl: any
  isBindBank: boolean
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public token = getToken() || ''
  public baseUrl: any
  public isBindBank: boolean = false

  public cookieToken: any = ''

  @Mutation
  private SET_COOKIE_TOKEN (token: any) {
    this.cookieToken = token
  }

  @Mutation
  private SET_TOKEN (token: string) {
    this.token = token
  }

  @Mutation
  private SET_BASEURL (baseUrl: any) {
    this.baseUrl = baseUrl
  }

  @Mutation
  private SET_ISBINDBANK (isBindBank: any) {
    this.isBindBank = isBindBank
  }

  @Action
  public SetIsBindBank (isBindBank: any) {
    this.SET_ISBINDBANK(isBindBank)
  }

  @Action
  public SetBaseUrl (baseUrl: any) {
    this.SET_BASEURL(baseUrl)
  }

  @Action
  public async SetToken (token: any) {
    setToken(token)
    this.SET_TOKEN(token)
  }

  @Action
  public async SetCookieToken (token: any) {
    this.SET_COOKIE_TOKEN(token)
  }

  @Action
  public ClearToken () {
    this.SET_COOKIE_TOKEN('')
  }

  @Action
  public ResetToken () {
    removeToken()
    this.SET_TOKEN('')
  }
}

export const UserModule = getModule(User)
