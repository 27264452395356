/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'jinggao': {
    width: 128,
    height: 101.37,
    viewBox: '0 0 1293 1024',
    data: '<path pid="0" _fill="#1296db" d="M743.316 54.65l487.155 797.156A113.179 113.179 0 011133.89 1024H159.528a113.179 113.179 0 01-96.471-172.194L550.265 54.703a113.179 113.179 0 01187.015-8.892l6.036 8.838zm395.156 853.369L651.318 110.915l-.755-1.078-1.024-.754a5.39 5.39 0 00-7.383 1.778L155 907.965a5.39 5.39 0 004.581 8.246h974.363a5.39 5.39 0 004.581-8.192z"/><path pid="1" _fill="#1296db" d="M646.737 323.368a75.29 75.29 0 0175.075 80.627l-17.354 242.904a57.883 57.883 0 01-115.442 0L571.66 403.995a75.29 75.29 0 0175.076-80.627zM592.842 808.421a53.895 53.895 0 10107.79 0 53.895 53.895 0 10-107.79 0z"/>'
  }
})
