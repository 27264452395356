import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue'

const newOnlineRoutes: RouteConfig = {
    path: '/newQuickpay',
    component: Layout,
    redirect: '/newQuickpay/selBank',
    meta: {
        title: 'newOnline',
        icon: 'table',
    },
    children: [
        {
            path: 'selBank',
            name: 'selBank',
            component: () => import('@/views/newOnline/selBank.vue'),
            meta: {
                title: 'selBank',
                icon: 'excel',
            }
        },
        {
            path: 'newGeneral',
            name: 'newGeneral',
            component: () => import('@/views/newOnline/bank/general/newGeneral.vue'),
            meta: {
                title: 'newGeneral',
                icon: 'excel',
            }
        },
    ]

}

export default newOnlineRoutes