// Set utils function parseTime to filter
export { parseTime } from '@/utils'
import { dateToTimezone } from '@/utils/dateTime'
import moment from 'moment'

// Filter for article status
export const articleStatusFilter = (status: string) => {
  const statusMap: { [key: string]: string } = {
    published: 'success',
    draft: 'info',
    deleted: 'danger'
  }
  return statusMap[status]
}

// Filter to uppercase the first character
export const uppercaseFirstChar = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const stringArrayToString = (list: string[]) => {
  let str:string = '' 
  for (let i = 0; i < list.length; i++) {
    str += list[i]
    if (i !== list.length - 1) {
      str += ';'
    }
  }
  return str
}

export const stringToStringArray = (str: string) => {
  return str.split(';')
}

// 第一个字母大写
export const firstToUpper = (str: string) => {
  return str.trim().replace(str[0], str[0].toUpperCase());
}


export const dateFormat = (obj: any) => {
  return obj ? moment(obj * 1000).format('YYYY-MM-DD HH:mm:ss') : ''
}