import { RouteConfig } from 'vue-router';
import Layout from '@/layout/index.vue'

const onlineRoutes: RouteConfig = {
    path: '/quickpay',
    component: Layout,
    redirect: '/quickpay/selBank',
    meta: {
        title: 'online',
        icon: 'table',
    },
    children: [
        {
            path: 'selBank',
            name: 'selBank',
            component: () => import('@/views/online/selBank.vue'),
            meta: {
                // title: 'depositList',
                title: 'selBank',
                icon: 'excel',
            }
        },
        {
            path: 'general',
            name: 'general',
            component: () => import('@/views/online/bank/general/general.vue'),
            meta: {
                // title: 'depositList',
                title: 'general',
                icon: 'excel',
            }
        },
    ]

}

export default onlineRoutes