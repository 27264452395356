import request from '@/utils/request'

// 常量
export const getConst = () =>
  request({
    url: '/merchant/checkout/counter/config',
    method: 'get'
  })

// 收银台页面
export const getCounter = () =>
  request({
    url: '/merchant/checkout/counter',
    method: 'get'
  })

export const setConter = (data: any) =>
  request({
    url: '/merchant/checkout/counter/shared',
    method: 'post',
    // timeout: 10 * 1000,
    data
  })
// 二维码收银台
// 银行列表
export const getQrBankList = () =>
  request({
    url: '/merchant/checkout/counter/qr/bind/bank',
    method: 'get'
  })

// 绑定银行
export const qrBindBank = (data: any) =>
  request({
    url: '/merchant/checkout/counter/qr/bind/bank',
    method: 'put',
    data
  })

// 转卡收银台
// 绑定银行
export const transferBindBank = (data: any) =>
  request({
    url: '/merchant/checkout/counter/transfer/bind/bank',
    method: 'put',
    data
  })

// 网银
// 绑定银行
export const quickpayBindBank = (data: any) =>
  request({
    url: '/merchant/checkout/counter/update/order',
    method: 'put',
    data
  })

// indonesiaqr获取二维码
export const getIndonesiaQr = () =>
  request({
    url: '/merchant/checkout/counter/getqr',
    method: 'get'
  })

// indonesiaqr获取二维码
export const tryGetIndonesiaQr = () =>
  request({
    url: '/merchant/checkout/counter/getqr',
    method: 'put'
  })

// inrqr提交upi
export const bindUpi = (data: any) =>
  request({
    url: '/merchant/checkout/counter/qr/bind/upi',
    method: 'patch',
    timeout: 10 * 1000,
    data
  })

// indiah5补单UTR
export const bindUtr = (data: any) =>
  request({
    url: '/merchant/checkout/counter/check/upi/paid',
    method: 'patch',
    data
  })

// 获取QR二维码
export const getQrCode = () =>
  request({
    url: '/merchant/checkout/counter/qrpic',
    method: 'get'
  })
