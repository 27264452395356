export default {
  button: {
    edit: '编辑',
    modify: '修改',
    return: '返回',
    add: '添加',
    del: '删除',
    authorization: '授权',
    delMessage: '确定删除吗？',
    modifyMessage: '确定修改吗？',
    cancel: '不了',
    confirm: '是的',
    save: '保存',
    copy: '复制'
  },
  route: {

  },
  navbar: {
    title: '收银台'
  },
  qr: {
    selectBank: '您还未绑定银行卡，请选择银行卡进行绑定。',
    amount: '金额：',
    ordernumber: '订单编号：',
    tips: '警告：每个二维码只能支付一次，第二次不能到账',
    loadingQR: 'Loading the QR code...',
    tipsText: '提示',
    tipsText1: '保存上方的二维码到相册，并打开任意一家手机银行app，进行扫码支付',
    tipsText2: '一个二维码只能支付一次，每次付款都要重新获取一次二维码图片'
  },
  transfer: {
    header: '转卡收银台',
    send: '发送',
    padding: '支付进行中，请稍后...'
  },
  result: {
    error: '您的支付订单已过期，请重新创建订单。如果您有任何问题，请随时与我们联系。',
    success: '支付成功！'
  },
  online: {
    bankName: '银行名称',
    payInfo: '支付信息',
    return: '上一步',
    loadingText: '处理中...请耐心等待',
    ordernumber: '订单编号',
    createdTime: '创建时间',
    username: '账号',
    password: '密码',
    loginText: '登录',
    safetyTips_1: '*安全的网上银行：请用您的',
    safetyTips_2: '账号和密码登录。',
    timeTips_1: '您将在',
    timeTips_2: '秒后登出！',
    h5_safetyTips: '*安全的网上银行',
    payInfotitle: '交易总结',
    bank: '银行：',
    amount: '金额：',
    accountNo: '付款代码：',
    time: '时间',
    otp_title: '添加第三方账户-确认。',
    otp_tips: '您将需要向我们的银行添加一个第三方账户，在要执行此交易的账号列表中。',
    otp_scb_tips: 'OTP的时间',
    otp_tcb_tips: 'The OTP Transaction ID',
    otp_other_tips: 'OTP的REF',
    otp_pin_tips: 'The PIN CODE REF',
    otp_input_tips: '请输入您的OTP：',
    confirm: '确认',
    bottom: {
      title: '注意：',
      tips1: '为确保您成功开设在线账户。',
      tips2: '交易可能需要几分钟才能完成，并反映到您的账户中。',
      tips3: '在任何情况下，请始终保存银行的参考号，以便以后需要我们的用户服务来验证您放的特定商品。'
    },
    steps: {
      step1: '选择银行',
      step2: '确认支付信息',
      step3: '网上银行登录'
    },
    stepsWeb: {
      step1: '线上银行登录',
      step2: '填写OTP',
      step3: '成功'
    },
    stepsH5: {
      step1: '登录',
      step2: '授权',
      step3: '结果'
    }
  }
}
