/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'card': {
    width: 200,
    height: 200,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M614.869 702.282H403.517v-35.226H614.87v35.226zm-246.577 0h-211.35v-35.226h211.35v35.226zm246.577-70.45H156.94v-35.226H614.87v35.225zM121.716 402.866v105.676h774.955V402.867H121.716zm774.955-70.45c0-19.374-15.851-35.225-35.225-35.225H156.94c-19.374 0-35.225 15.85-35.225 35.225v52.837h774.955v-52.837zm-739.73 457.927h704.505c19.374 0 35.225-15.85 35.225-35.225V526.155H121.716V755.12c0 19.374 15.851 35.225 35.225 35.225zm704.505 35.226H156.94c-38.923 0-70.45-31.527-70.45-70.45V332.416c0-38.924 31.527-70.45 70.45-70.45h704.505c38.923 0 70.45 31.526 70.45 70.45V755.12c0 38.924-31.527 70.45-70.45 70.45z" _fill="#bfbfbf"/>'
  }
})
