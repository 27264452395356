






import { Component, Vue, Watch } from 'vue-property-decorator'
// import '@/utils/new-relic-browser.js'

@Component({
  name: 'App',
  components: {}
})
export default class extends Vue {}
