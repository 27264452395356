import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import router from '@/router'
import { AppModule } from '@/store/modules/app'
import { getToken } from './cookies'
import Cookies from 'js-cookie'
import { UserModule } from '@/store/modules/user'

const hostname = window.location.hostname
const http = window.location.protocol
const baseURL =
  hostname === 'localhost'
    ? process.env.VUE_APP_BASE_API
    : http + '//api' + hostname.substring(hostname.indexOf('.')) + '/api'
// UserModule.SetBaseUrl(baseURL)
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  timeout: 10 * 1000
})

// Request interceptors
service.interceptors.request.use(
  config => {
    if (UserModule.cookieToken) {
      config.headers['Authorization'] = 'Token ' + UserModule.cookieToken
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  response => {
    if (
      response.status !== 200 &&
      response.status !== 201 &&
      response.status !== 202 &&
      response.status !== 204
    ) {
      Message({
        message: response.data.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      if (response.data.code === 401) {
        MessageBox.alert(
          AppModule.chatInfo?.expire_message
            ? AppModule.chatInfo.expire_message
            : 'Token has expired, Or your payment order has expired, please re-create the order. If you have any questions, please feel free to contact us.',
          {
            showClose: false,
            confirmButtonText: 'yes',
            type: 'warning',
            callback: () => {
              router.replace({ name: 'error' })
            }
          }
        )
      }
      return Promise.reject(new Error(response.data.message || 'Error'))
    } else {
      return response.data
    }
  },
  error => {
    const response = error.response
    if (response && response.data) {
      if (router.currentRoute.path.includes('new_indiah5')) {
        MessageBox.alert(response.data.message, {
          showClose: false,
          confirmButtonText: 'OK',
          type: 'error',
          callback: () => {
            console.log(response.data.message)
          }
        })
      } else {
        Message({
          message: response.data.message,
          type: 'error',
          duration: 5 * 1000
        })
      }
    }
    if (response.data.code === 401 && response.config.url !== 'manage/login') {
      MessageBox.alert(
        AppModule.chatInfo?.expire_message
          ? AppModule.chatInfo.expire_message
          : 'Token has expired, Or your payment order has expired, please re-create the order. If you have any questions, please feel free to contact us.',
        {
          showClose: false,
          confirmButtonText: 'yes',
          type: 'warning',
          callback: () => {
            router.replace({ name: 'error' })
          }
        }
      )
    }
    return Promise.reject(error)
  }
)

export default service
