export default {
  button: {
    edit: 'แก้ไข',
    modify: 'แก้ไข',
    return: 'กลับ',
    add: 'เพิ่ม',
    del: 'ลบ',
    authorization: 'การอนุญาต',
    cancel: 'ยกเลิก',
    confirm: 'ใช่',
    save: 'บันทึก',
    copy: 'copy'
  },
  route: {},
  navbar: {
    title: 'เคาน์เตอร์ชำระเงิน'
  },
  qr: {
    selectBank: 'คุณไม่ได้ผูกบัตรธนาคารโปรดเลือกบัตรธนาคารที่จะผูก',
    amount: 'Amount：',
    ordernumber: 'Order No：',
    tips:
      'คำเตือน: แต่ละรหัส QR สามารถชำระได้เพียงครั้งเดียวและครั้งที่สองไม่สามารถให้เครดิตได้!',
    loadingQR: 'Loading the QR code...',
    tipsText: 'Tips',
    tipsText1:
      'บันทึกรหัส QR ด้านบนลงในอัลบั้ม แล้วเปิดแอปธนาคารบนมือถือใดๆ เพื่อสแกนรหัสเพื่อชำระเงิน',
    tipsText2:
      'สามารถชำระเงินรหัส QR ได้เพียงครั้งเดียว และจะต้องได้รับรูปภาพรหัส QR อีกครั้งสำหรับการชำระเงินแต่ละครั้ง'
  },
  transfer: {
    header: 'เคาน์เตอร์ชำระเงิน',
    send: 'ส่ง',
    padding: 'กำลังดำเนินการชำระเงินโปรดรอสักครู่ ...'
  },
  result: {
    error:
      'คำสั่งชำระเงินของคุณหมดอายุแล้วโปรดสร้างคำสั่งซื้อใหม่ หากคุณมีคำถามใด ๆ โปรดอย่าลังเลที่จะติดต่อเรา',
    success: 'ชำระเงินสำเร็จ!'
  },
  online: {
    bankName: 'ชื่อธนาคาร',
    payInfo: 'ข้อมูลการชำระเงิน',
    return: 'กลับ',
    loadingText: 'กำลังดำเนินการโปรดรอสักครู่',
    ordernumber: 'เลขที่ใบสั่งซื้อ',
    createdTime: 'เวลาสร้าง',
    username: 'รหัสผู้ใช้งาน',
    password: 'รหัสผ่าน',
    loginText: 'เข้าสู่ระบบ',
    safetyTips_1:
      '*บริการธนาคารทางอินเทอร์เน็ตที่ปลอดภัย: กรุณาเข้าสู่ระบบด้วยบัญชี ',
    safetyTips_2: 'และรหัสผ่านของคุณ',
    timeTips_1: 'เหลืออีก ',
    timeTips_2: ' วินาที',
    h5_safetyTips: 'การชำระเงินออนไลน์ที่ปลอดภัย',
    payInfoTitle: 'สรุปธุรกรรม',
    bank: 'ธนาคาร：',
    amount: 'จำนวน：',
    accountNo: 'บัญชีการชำระเงิน：',
    time: 'เวลา：',
    otp_title: 'เพิ่มบัญชีบุคคลที่สาม - ยืนยัน。',
    otp_tips:
      'คุณจะต้องเพิ่มบัญชีบุคคลที่สามในธนาคารของเราในรายการบัญชีเพื่อทำธุรกรรมนี้',
    otp_scb_tips: 'เวลา OTP',
    otp_tcb_tips: 'The OTP Transaction ID',
    otp_other_tips: 'OTP REF',
    otp_pin_tips: 'PIN CODE REF',
    otp_input_tips: 'กรุณากรอกรหัส OTP ของคุณ：',
    confirm: 'ยืนยัน',
    bottom: {
      title: 'บันทึก：',
      tips1: 'เพื่อให้แน่ใจว่าคุณเปิดบัญชีออนไลน์ได้สำเร็จ',
      tips2: 'คุณต้องคลิกปุ่มเพียงครั้งเดียวและอย่ารีเฟรชหน้าด้วยตนเอง',
      tips3: 'ธุรกรรมอาจใช้เวลาสักครู่จึงจะเสร็จสมบูรณ์และปรากฏในบัญชีของคุณ',
      tips4:
        'ไม่ว่ากรณีใด ๆ โปรดเก็บหมายเลขอ้างอิงของธนาคารไว้เสมอ เพื่อที่เราสามารถตรวจสอบธุรกรรมหรือผลิตภัณฑ์ของท่านได้ในอนาคต'
    },
    steps: {
      step1: 'เลือกธนาคาร',
      step2: 'ยืนยันข้อมูลการชำระเงิน',
      step3: 'เข้าสู่ระบบธนาคารออนไลน์'
    },
    stepsWeb: {
      step1: 'เข้าสู่ระบบธนาคารออนไลน์',
      step2: 'กรอก OTP',
      step3: 'ผลลัพธ์'
    },
    stepsH5: {
      step1: 'เข้าสู่ระบบ',
      step2: 'การอนุญาต',
      step3: 'ผลลัพธ์'
    }
  }
}
