import Vue, { DirectiveOptions } from 'vue'

import 'normalize.css'
import ElementUI from 'element-ui'
import SvgIcon from 'vue-svgicon'

import '@/styles/element-variables.scss'
import '@/styles/index.scss'

import App from '@/App.vue'
import store from '@/store'
import { AppModule } from '@/store/modules/app'
import router from '@/router'
import i18n from '@/lang'
import '@/icons/components'
import '@/permission'
import * as directives from '@/directives'
import * as filters from '@/filters'

// import { addListener, launch } from 'devtools-detector'

// 将我们注册的国际化组件实例诸如element中
Vue.use(ElementUI, {
  size: AppModule.size, // Set element-ui default size
  i18n: (key: string, value: string) => i18n.t(key, value)
})

Vue.use(SvgIcon, {
  tagName: 'svg-icon', // 图标组件名称
  defaultWidth: '1em',
  defaultHeight: '1em'
})

// Register global directives
Object.keys(directives).forEach(key => {
  Vue.directive(key, (directives as { [key: string]: DirectiveOptions })[key])
})

// Register global filter functions
Object.keys(filters).forEach(key => {
  Vue.filter(key, (filters as { [key: string]: Function })[key])
})

Vue.config.productionTip = false

// let url = window.location.href.indexOf('localhost') !== -1 // 本地路径 为true, 线上路径为false
// let backDoorUrl = window.location.href // 默认路径
// let backDoor: any = null

// console.log(backDoorUrl, '默认路径') // url
// console.log(url, '本地路径') // localhost为 true
// console.log(backDoor, '拼接/songshu') // 拼接/songshu true

// if (url === false && backDoorUrl) {
//   backDoor = backDoorUrl.indexOf('/songshu') !== -1 // 线上为false
//   if (backDoor === false) {
//     addListener(isOpen =>
//       isOpen ? (location.href = 'https://www.baidu.com') : ''
//     )
//     launch()
//   }
// } else if (url === true && backDoorUrl) {
//   backDoorUrl = window.location.href + '/songshu'
//   backDoor = backDoorUrl.indexOf('/songshu') !== -1
// }

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
