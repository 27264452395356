/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'saoma1': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" d="M985.6 555H39.4C19.3 555 3 536 3 512.59c0-23.41 16.3-42.41 36.4-42.41h946.2c20.12 0 36.4 19 36.4 42.41 0 23.41-16.27 42.41-36.4 42.41zm-72.8 469H694.31c-20.14 0-36.42-16.28-36.42-36.42s16.28-36.42 36.42-36.42H912.8c20.1 0 36.42-16.31 36.42-36.41V732.68c0-20.14 16.27-36.42 36.42-36.42 20.14 0 36.41 16.28 36.41 36.42v182.08c-.01 60.22-49.02 109.24-109.25 109.24zm-619.23 0H111.49C51.26 1024 2.25 974.98 2.25 914.75V732.68c0-20.14 16.31-36.42 36.41-36.42 20.1 0 36.42 16.28 36.42 36.42v182.08c0 20.1 16.35 36.41 36.41 36.41h182.08c20.1 0 36.42 16.28 36.42 36.42 0 20.13-16.32 36.41-36.42 36.41zM37.66 328.93c-20.1 0-36.41-16.31-36.41-36.41V110.45C1.25 50.21 50.26 1.2 110.49 1.2h182.08c20.1 0 36.42 16.31 36.42 36.42 0 20.1-16.31 36.41-36.42 36.41H110.49c-20.06 0-36.41 16.35-36.41 36.42v182.08c0 20.09-16.32 36.4-36.42 36.4zm948.97 0c-20.14 0-36.42-16.31-36.42-36.41V110.45c0-20.07-16.31-36.42-36.42-36.42H695.31c-20.14 0-36.42-16.31-36.42-36.41 0-20.1 16.28-36.42 36.42-36.42H913.8c60.23 0 109.25 49.01 109.25 109.25v182.08c-.01 20.09-16.28 36.4-36.42 36.4z"/>'
  }
})
