
















import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/modules/app'
import { UserModule } from '@/store/modules/user'
import { getLanguage } from '@/utils/cookies'
import Cookies from 'js-cookie'

@Component({
  name: 'Navbar',
  components: {}
})
export default class extends Vue {
  public logo: any = ''
  get device() {
    return AppModule.device.toString()
  }

  public lang = getLanguage()

  created() {
    this.logo = Cookies.get('logo')
  }
}
