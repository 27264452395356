import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'

/* Layout */
import Layout from '@/layout/index.vue'

/* Router modules */
import onlineRouters from './modules/online'
import indiah5Routers from './modules/indiah5'
import newOnlineRouters from './modules/newOnline'
Vue.use(Router)

/*
  注意: children.length>=1时才出现子菜单
  细节见: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/

/*
  name:'router-name'             在使用<keep-alive>时，name字段是必需的，它还应该匹配其组件的name属性
                                 细节见: https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      如果设置为“noredirect”，当单击面包屑时将不会触发重定向操作
  meta: {
    roles: ['admin', 'editor']   将控制页面角色(允许设置多个角色)
    title: 'title'               在子菜单和面包屑中显示的名称(推荐设置)
    icon: 'svg-name'             图标显示在侧边栏
    hidden: true                 如果为true，此路径将不会显示在侧边栏中(默认为false)
    alwaysShow: true             如果为true，将始终显示根菜单(默认为false)
                                 如果为false，则在子路由少于或等于一个时隐藏根菜单
    breadcrumb: false            如果为false，项目将隐藏在面包屑中(默认为true)
    noCache: true                如果为true，页面将不会被缓存(默认为false)
    affix: true                  如果为true，标签将附着在标签视图中(默认为false)
    activeMenu: '/example/list'  如果设置路径，侧边栏将突出显示您设置的路径
  }
*/

export const constantRoutes: RouteConfig[] = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () =>
          import(
            /* webpackChunkName: "redirect" */ '@/views/redirect/index.vue'
          )
      }
    ]
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue'),
    meta: { hidden: true }
  },
  {
    path: '/401',
    component: () =>
      import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue'),
    meta: { hidden: true }
  },
  {
    path: '/',
    redirect: '/401',
    component: Layout,
    children: [
      onlineRouters,
      indiah5Routers,
      newOnlineRouters,
      {
        path: 'qr',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/qr/index.vue'),
        name: 'qr',
        meta: {
          title: 'qr',
          icon: 'dashboard'
        }
      },
      {
        path: 'qrEn',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/qrEn/index.vue'),
        name: 'qrEn',
        meta: {
          title: 'qrEn',
          icon: 'dashboard'
        }
      },
      {
        path: 'qrNew',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/qrNew/index.vue'),
        name: 'qrNew',
        meta: {
          title: 'qrNew',
          icon: 'dashboard'
        }
      },
      {
        path: 'thaiqr',
        component: () => import('@/views/thaiqr/index.vue'),
        name: 'thaiqr',
        meta: {
          title: 'thaiqr'
        }
      },
      {
        path: 'usdtqr',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/usdtqr/index.vue'
          ),
        name: 'usdtqr',
        meta: {
          title: 'usdtqr',
          icon: 'dashboard'
        }
      },
      {
        path: 'usdtVietnam',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/usdtVietnam/index.vue'
          ),
        name: 'usdtVietnam',
        meta: {
          title: 'usdtVietnam',
          icon: 'dashboard'
        }
      },
      {
        path: 'usdtZh',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/usdtZh/index.vue'
          ),
        name: 'usdtZh',
        meta: {
          title: 'usdtZh',
          icon: 'dashboard'
        }
      },
      {
        path: 'persian',
        component: () =>
          import(/* webpackChunkName: "persian" */ '@/views/persian/index.vue'),
        name: 'persian',
        meta: {
          title: 'persian',
          icon: 'dashboard'
        }
      },
      {
        path: 'indonesiaqr',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/indonesiaqr/index.vue'
          ),
        name: 'indonesiaqr',
        meta: {
          title: 'IndonesiaQr',
          icon: 'dashboard'
        }
      },
      {
        path: 'inrqr',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/inrqr/index.vue'),
        name: 'indonesiaqr',
        meta: {
          title: 'IndonesiaQr',
          icon: 'dashboard'
        }
      },
      {
        path: 'inrh5',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/inrh5/index.vue'),
        name: 'indonesiaqr',
        meta: {
          title: 'inrh5',
          icon: 'dashboard'
        }
      },
      {
        path: 'shared',
        component: () =>
          import(/* webpackChunkName: "shared" */ '@/views/shared/index.vue'),
        name: 'shared',
        meta: {
          title: 'shared',
          icon: 'dashboard'
        }
      },
      {
        path: 'transfer',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/transfer/index.vue'
          ),
        name: 'transfer',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'newTransfer',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/newTransfer/index.vue'
          ),
        name: 'newTransfer',
        meta: {
          title: 'newTransfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'brlqr',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/brlqr/index.vue'),
        name: 'brlqr',
        meta: {
          title: 'brlqr',
          icon: 'dashboard'
        }
      },
      {
        path: 'vnDeposit',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/vnDeposit/index.vue'
          ),
        name: 'vnDeposit',
        meta: {
          title: 'vnDeposit',
          icon: 'dashboard'
        }
      },
      {
        path: 'KBankTransfer',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/KBankTransfer/index.vue'
          ),
        name: 'KBankTransfer',
        meta: {
          title: 'KBankTransfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'momo',
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/momo/index.vue'),
        name: 'momo',
        meta: {
          title: 'momo',
          icon: 'dashboard'
        }
      },
      {
        path: 'jumpPage',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/jumpPage.vue'
          ),
        name: 'jumpPage'
      },
      {
        path: 'error',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/error.vue'
          ),
        name: 'error',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'errEn',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/errEn.vue'
          ),
        name: 'errEn',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'success',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/success.vue'
          ),
        name: 'success',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'successEn',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/successEn.vue'
          ),
        name: 'successEn',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'vietnamScccess',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/vietnamScccess.vue'
          ),
        name: 'vietnamScccess',
        meta: {}
      },
      {
        path: 'vietnamErr',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/vietnamErr.vue'
          ),
        name: 'vietnamErr',
        meta: {}
      },
      {
        path: 'shutDown',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/results-page/shutDown.vue'
          ),
        name: 'shutDown',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      },
      {
        path: 'sinhaleseQR',
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ '@/views/sinhaleseQR/index.vue'
          ),
        name: 'sinhaleseQR',
        meta: {
          title: 'transfer',
          icon: 'dashboard'
        }
      }
    ]
  }
]

export const asyncRoutes: RouteConfig[] = []

const createRouter = () =>
  new Router({
    mode: process.env.NODE_ENV === 'oss' ? 'hash' : 'history', // Disabled due to Github Pages doesn't support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition
      } else {
        return { x: 0, y: 0 }
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes
  })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter () {
  const newRouter = createRouter()
  ;(router as any).matcher = (newRouter as any).matcher // reset router
}

export default router
