/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chenggong': {
    width: 128,
    height: 128,
    viewBox: '0 0 1024 1024',
    data: '<path pid="0" _fill="#1296db" d="M512 1024c282.768 0 512-229.232 512-512a509.648 509.648 0 00-67.808-254.816 513.952 513.952 0 00-80.528-105.6C782.88 58 654.24 0 512 0 229.232 0 0 229.232 0 512c0 282.768 229.232 512 512 512zm0-28.448c-267.056 0-483.552-216.48-483.552-483.552 0-267.056 216.48-483.552 483.552-483.552a481.888 481.888 0 01343.456 143.168 485.504 485.504 0 0176.064 99.744A481.152 481.152 0 01995.552 512c0 267.056-216.48 483.552-483.552 483.552zM752.288 320H832L444.88 736 224 503.088h94.208c3.568 0 7.008 1.344 9.648 3.76l117.024 107.92 297.456-290.72a14.224 14.224 0 019.952-4.048z"/>'
  }
})
