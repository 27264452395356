import Cookies from 'js-cookie'

// App
const sidebarStatusKey = 'sidebar_status'
export const getSidebarStatus = () => Cookies.get(sidebarStatusKey)
export const setSidebarStatus = (sidebarStatus: string) =>
  Cookies.set(sidebarStatusKey, sidebarStatus)
export const romoveStatus = () => Cookies.remove(sidebarStatusKey)

const languageKey = 'language'
export const getLanguage = () => Cookies.get(languageKey)
export const setLanguage = (language: string) =>
  Cookies.set(languageKey, language)

const sizeKey = 'size'
export const getSize = () => Cookies.get(sizeKey)
export const setSize = (size: string) => Cookies.set(sizeKey, size)

// otp
const isLoadingKey = 'isLoading'
export const getIsLoading = () => Cookies.get(isLoadingKey)
export const setIsLoading = (isLoading: string) =>
  Cookies.set(isLoadingKey, isLoading)
export const romoveLoading = () => Cookies.remove(isLoadingKey)

const orderStatus = 'orderStatus'
export const getOrderStatus = () => Cookies.get(orderStatus)
export const setOrderStatus = (status: string) =>
  Cookies.set(orderStatus, status)

const bankList = 'bankList'
export const getBankList = () => Cookies.get(bankList)
export const setBankList = (list: string) => Cookies.set(bankList, list)

// upi
const upi = 'upi'
export const getUpi = () => Cookies.get(upi)
export const setUpi = (str: string) => Cookies.set(upi, str)
export const removeUpi = () => Cookies.remove(upi)

// User
// const tokenKey = 'vue_typescript_admin_access_token'
// export const getToken = () => Cookies.get(tokenKey)
// export const setToken = (token: string) => Cookies.set(tokenKey, token)
// export const removeToken = () => Cookies.remove(tokenKey)

function getCookie (cookieName) {
  const strCookie = document.cookie
  const cookieList = strCookie.split(';')

  for (let i = 0; i < cookieList.length; i++) {
    const arr = cookieList[i].split('=')
    if (cookieName === arr[0].trim()) {
      return arr[1]
    }
  }

  return ''
}

const tokenKey = 'token'
// export const getToken = () => sessionStorage.getItem(tokenKey)
export const getToken = () => {
  try {
    return sessionStorage.getItem(tokenKey)
  } catch (error) {
    return getCookie(tokenKey)
  }
}
export const setToken = (token: string) =>
  sessionStorage.setItem(tokenKey, token)
export const removeToken = () => sessionStorage.removeItem(tokenKey)
