import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators'
import { getSidebarStatus, getSize, setSidebarStatus, setLanguage, setSize } from '@/utils/cookies'
import { getLocale } from '@/lang'
import store from '@/store'

export enum DeviceType {
  Mobile,
  Desktop,
}

export interface IAppState {
  device: DeviceType
  sidebar: {
    opened: boolean
    withoutAnimation: boolean
  }
  language: string
  size: string
  navbar: {
    open: boolean
  }
  enterprise: {
    name: string
    manage_domain: string
    merchant_domain: string
    timezone: string
    available_currency: any[]
  }
  constant: any | null // 常量表
  baseUrl: any // 后端地址
  chatInfo: any // 交互信息
}

@Module({ dynamic: true, store, name: 'app' })
class App extends VuexModule implements IAppState {
  public enterprise = {
     name: '',
     manage_domain: '',
     merchant_domain: '',
     timezone: '' ,
     available_currency: [] 
  }
  public constant: any | null = null
  public baseUrl: any = ''
  public orderInfo: any = ''
  public sidebar = {
    opened: getSidebarStatus() !== 'closed',
    withoutAnimation: false
  }
  public navbar = {
    open: true
  }
  public device = DeviceType.Desktop
  public screenWidth = window.screen.availWidth
  public screenHeight = window.screen.availHeight
  public language = getLocale()
  public size = getSize() || 'samll'
  public chatInfo: any = null

  @Mutation
  private TOGGLE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = !this.sidebar.opened
    this.sidebar.withoutAnimation = withoutAnimation
    if (this.sidebar.opened) {
      setSidebarStatus('opened')
    } else {
      setSidebarStatus('closed')
    }
  }

  @Mutation
  private CLOSE_SIDEBAR(withoutAnimation: boolean) {
    this.sidebar.opened = false
    this.sidebar.withoutAnimation = withoutAnimation
    setSidebarStatus('closed')
  }

  @Mutation
  private TOGGLE_DEVICE(device: DeviceType) {
    this.device = device
  }

  @Mutation
  private SET_LANGUAGE(language: string) {
    this.language = language
    setLanguage(this.language)
  }

  @Mutation
  private SET_SIZE(size: string) {
    this.size = size
    setSize(this.size)
  }


  @Mutation
  private SET_BASEURL(baseUrl: any) {
    this.baseUrl = baseUrl
  }

  @Mutation
  private SET_NAVBAR(is: boolean) {
    this.navbar.open = is
  }

  @Mutation
  private SET_CHATINFO(chatInfo: any) {
    this.chatInfo = chatInfo
  }


  @Action
  public SetChatInfo(chatInfo: any) {
    this.SET_CHATINFO(chatInfo)
  }

  @Action
  public SetNavbar(is: boolean) {
    this.SET_NAVBAR(is)
  }

  @Action
  public SetBaseUrl(baseUrl: any) {
    this.SET_BASEURL(baseUrl)
  }

  @Action
  public ToggleSideBar(withoutAnimation: boolean) {
    this.TOGGLE_SIDEBAR(withoutAnimation)
  }

  @Action
  public CloseSideBar(withoutAnimation: boolean) {
    this.CLOSE_SIDEBAR(withoutAnimation)
  }

  @Action
  public ToggleDevice(device: DeviceType) {
    this.TOGGLE_DEVICE(device)
  }

  @Action
  public SetLanguage(language: string) {
    this.SET_LANGUAGE(language)
  }

  @Action
  public SetSize(size: string) {
    this.SET_SIZE(size)
  }
}

export const AppModule = getModule(App)
