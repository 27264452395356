import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Route } from 'vue-router'
import i18n from '@/lang' // Internationalization
import settings from './settings'
import { getConst } from '@/api/index'
import { setIsLoading, getToken, setToken } from './utils/cookies'
import Cookies from 'js-cookie'
import { UserModule } from '@/store/modules/user'

NProgress.configure({ showSpinner: false })

const whiteList = [
  '/qr',
  '/qrEn',
  '/qrNew',
  '/usdtqr',
  '/persian',
  '/inrqr',
  '/indonesiaqr',
  '/transfer',
  '/newTransfer',
  '/quickpay',
  '/quickpay/selBank',
  '/newQuickpay',
  '/newQuickpay/selBank',
  '/momo',
  '/vnDeposit',
  '/KBankTransfer',
  '/brlqr',
  '/inrh5',
  '/new_indiah5/selBank',
  '/shared',
  '/thaiqr',
  '/usdtZh',
  '/usdtVietnam',
  '/sinhaleseQR'
]

router.beforeEach(async (to: Route, _: Route, next: any) => {
  // Start progress bar
  NProgress.start()
  if (whiteList.includes(to.path)) {
    const urlToken = to.query.token
    if (urlToken) {
      // sessionStorage.setItem('token', urlToken as string)
      // Cookies.set('token', urlToken)
      UserModule.SetCookieToken(urlToken)
      await getConst()
        .then((res: any) => {
          sessionStorage.setItem('logo', res.logo)
          sessionStorage.setItem('icon', res.icon)
          sessionStorage.setItem('title', res.title)
          sessionStorage.setItem('return_url', res.return_url)
          sessionStorage.setItem('currency', res.currency)
          Cookies.set('logo', res.logo)
          Cookies.set('icon', res.icon)
          Cookies.set('title', res.title)
          Cookies.set('return_url', res.return_url)
          Cookies.set('currency', res.currency)
        })
        .catch(() => {})
      next()
    } else {
      UserModule.ClearToken()
      await next({ name: 'error' })
    }
  } else {
    // 获取token
    const token = UserModule.cookieToken
    // token不存在
    if (!token) {
      await next({ name: 'error' })
    } else {
      await next()
    }
  }

  NProgress.done()
})

router.afterEach(async (to: Route) => {
  document.title = 'Cashier'
  if (to.query['language']) {
    i18n.locale = to.query['language'] + ''
  }
  // Finish progress bar
  NProgress.done()
})
